import React, { useState } from 'react'
// Types
import { QuestionAnswerProps } from '../../../../types/blocks'
// Components
import { Box, Flex, Icons, Text } from '../../../../atoms'
import { ContentMd } from '../../../commons'

const QA: React.FC<QuestionAnswerProps> = ({ question, answer }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Box borderBottomWidth={1} borderBottomStyle={'solid'} borderColor={'grey10'}>
      <Flex clickable justifyContent={'space-between'} alignItems={'center'} py={5} onClick={() => setOpen(!isOpen)}>
        <Box flex={'1'}>
          <Text type={'large'} fontWeight={'ultraBold'}>
            {question}
          </Text>
        </Box>
        <Box flex={'0 0 auto'} color={'dark'} ml={4}>
          {isOpen ? <Icons.Minus size={18} /> : <Icons.Plus size={18} />}
        </Box>
      </Flex>
      {isOpen && (
        <Box mb={5}>
          <ContentMd content={answer} textType={'regular'} />
        </Box>
      )}
    </Box>
  )
}

export default QA
