import React from 'react'
import { SECTION_MARGIN } from '../../../utils/constants'
// Types
import { SectionFAQ } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading } from '../../../atoms'
import { ContentMd } from '../../commons'
import QA from './QA'

const FAQ: React.FC<SectionFAQ> = (props) => {
  const { id, sectionId, headline, content, qas } = props
  return (
    <Box as={'section'} id={sectionId || id} my={SECTION_MARGIN}>
      <Grid>
        <Row between={'xs'}>
          <Col xs={12} md={6} lg={5}>
            <Box pt={5}>
              <Heading as={'h2'} type={'heading1'}>
                {headline}
              </Heading>
              {!!content && (
                <Box mt={6}>
                  <ContentMd content={content} />
                </Box>
              )}
            </Box>
          </Col>
          {qas.length > 0 && (
            <Col xs={12} md={6} lg={6}>
              <Box mt={[6, 6, 0]}>
                {qas.map((qa) => (
                  <QA key={qa.id} {...qa} />
                ))}
              </Box>
            </Col>
          )}
        </Row>
      </Grid>
    </Box>
  )
}

export default FAQ
